import initialState from '@redux/index';
import { atomWithStore } from 'jotai-redux';
import { atom } from 'jotai/index';
import { createStore } from 'redux';

// Do not use this! This is only for hydration
export const DO_NOT_IMPORT_nested_redux_atom = atom(
	atomWithStore(createStore(initialState)),
);
export const reduxAtom = atom((get) =>
	get(get(DO_NOT_IMPORT_nested_redux_atom)),
);
