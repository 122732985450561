import { membershipApiKey } from '@settings';
import MembershipService from '@xo-union/sdk-membership';
import Hoek from '../../../containers/InlineRFQ/utils/hoek';

class MembershipAPI {
	constructor(member, data) {
		// biome-ignore lint/complexity/useOptionalChain: <explanation>
		this._hasMember = !!(member && member.id);
		this._data = data ? this._map(data) : null;
		this._client = new MembershipService({
			clientOptions: {
				apiKey: membershipApiKey,
				includeWeddingProperties: true,
			},
		});
	}

	_map(data) {
		// deep clone
		const input = JSON.parse(JSON.stringify(data));

		const transformAndClean = (source, mappings) => {
			const transformed = Hoek.transform(source, mappings);
			// remove undefined properties
			const cleaned = Hoek.merge({}, transformed, false, true);
			return cleaned;
		};

		const mappings = {
			email: 'email',
			first_name: 'first_name',
			last_name: 'last_name',
		};

		const member = transformAndClean(input, mappings);

		return Object.keys(member).length ? member : null;
	}

	update() {
		// not logged or no data passed, then exit
		if (!(this._hasMember && this._data)) {
			return Promise.resolve();
		}

		return this._client.updateMember(this._data);
	}
}

export default MembershipAPI;
