import CONSTANTS from '../../pages/Storefront/containers/vendor_info/components/favorites_button/constants';

export const initialState = {
	filled: false,
	weddingId: undefined,
	savedVendorId: null,
};

export default function favoritesReducer(state = initialState, action) {
	switch (action.type) {
		case CONSTANTS.FILL_FAVORITE_HEART:
			return { ...state, filled: true };
		case CONSTANTS.EMPTY_FAVORITE_HEART:
			return { ...state, filled: false };
		case CONSTANTS.SET_WEDDING_ID:
			return { ...state, weddingId: action.payload };
		case CONSTANTS.SET_SAVED_VENDOR_ID:
			return { ...state, savedVendorId: action.payload };
		default:
			return state;
	}
}
