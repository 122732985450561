export const initialState = {
	pageType: '',
};
const PAGE_TYPE = 'PAGE_TYPE';

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case PAGE_TYPE:
			return {
				...state,
				pageType: action.payload,
			};

		default:
			return state;
	}
}

export const setPageType = (pageType) => ({
	type: PAGE_TYPE,
	payload: pageType,
});

export const getPageType = (state) => state.page.pageType;
