import storefrontsQuery from '../../../api/storefronts';

export const MULTI_VENDOR_LOADED = 'MULTI_VENDOR_LOADED';

export const initialState = {
	vendors: null,
	isFetching: true,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case MULTI_VENDOR_LOADED:
			return {
				...state,
				vendors: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
}

export function fetchMultipleVendors(ids) {
	return (dispatch) => {
		return storefrontsQuery({ ids })
			.then((response) => {
				const { storefronts } = response.data.data;
				dispatch({
					type: MULTI_VENDOR_LOADED,
					payload: storefronts?.profiles,
				});
			})
			.catch(() => {
				throw new Error(`fetchMultipleVendors failed id ${ids}`);
			});
	};
}
