import { ImagePreloadRenderer } from '@xo-union/tk-component-picture';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const renderPreload = (props) => (
	<Helmet>
		<link {...props} />
	</Helmet>
);

const HelmetImagePreloadRenderer = ({ children }) => (
	<ImagePreloadRenderer renderPreload={renderPreload}>
		{children}
	</ImagePreloadRenderer>
);

export default HelmetImagePreloadRenderer;
