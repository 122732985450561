import axios from 'axios';

export const DEFAULT_TIMEOUT = 5000;

function fetchAPI(request = {}) {
	const defaultOptions = {
		timeout: DEFAULT_TIMEOUT,
	};
	request.method = request.method ? request.method.toLowerCase() : 'get';
	return axios({ ...defaultOptions, ...request });
}

export default () => (dispatch) => (action) => {
	const { type, request } = action;

	if (!request || !request.url) {
		dispatch(action);

		/* returns promise for api consistency */
		return Promise.resolve();
	}

	/* dispatch the original action */
	dispatch({ type });

	return fetchAPI(request)
		.then(
			(payload) => {
				dispatch({
					type: `${type}_SUCCESS`,
					payload,
				});

				return payload;
			},
			(error) => {
				dispatch({
					type: `${type}_ERROR`,
					error: error || 'Unknown',
				});

				return error;
			},
		)
		.then((response) => response);
};
