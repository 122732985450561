export const initialState: Redux.RequestInfo = {
	info: {
		acceptEncoding: '',
		completed: null,
		cors: null,
		host: '',
		hostname: '',
		id: '',
		received: null,
		referrer: '',
		remoteAddress: '',
		remotePort: null,
		responded: null,
	},
	params: {
		path: '',
	},
	path: '',
	url: '',
};

const requestInfoReducer = (
	state = initialState,
	action: RequestInfoNS.ActionTypes,
) => {
	switch (action.type) {
		case 'SET_REQUEST_INFO':
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default requestInfoReducer;
