export const receiveMultipleAssignments = (
	assignments: Experiments.Mappings,
): Experiments.ReceiveMultipleAssignments => ({
	type: 'experiments/RECEIVE_MULTIPLE_ASSIGNMENTS',
	assignments,
});

export const receiveExperimentConfigurations = (
	configurations: Experiments.Configurations,
): Experiments.ReceiveExperimentConfigurations => ({
	type: 'experiments/RECEIVE_EXPERIMENT_CONFIGURATIONS',
	configurations,
});

export const setGatekeeper = (query?: string): Experiments.SetGatekeeper => ({
	type: 'experiments/SET_GATEKEEPER',
	query,
});
