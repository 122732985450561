import {
	type TypedUseSelectorHook,
	useDispatch,
	useSelector,
} from 'react-redux';
import type { Dispatch } from 'redux';
import type { ActionTypes, State } from 'types/redux';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => Dispatch<ActionTypes>;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;
