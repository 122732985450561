import type { AppliedFilterPillProps } from '@typings/search';

export const startingPriceNumberFormat = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

interface GetVanityFacetValues {
	[key: string]: { minInput: string; maxInput: string };
}

export const getVanityFacetValues: GetVanityFacetValues = {
	'REC-inexpensive': { minInput: '', maxInput: '2500' },
	'REC-affordable': { minInput: '2500', maxInput: '5000' },
	'REC-luxury': { minInput: '10000', maxInput: '' },
	'WPH-affordable': { minInput: '', maxInput: '1000' },
	'VID-affordable': { minInput: '', maxInput: '1500' },
	'VID-luxury': { minInput: '2500', maxInput: '' },
	'FLO-affordable': { minInput: '1000', maxInput: '1999' },
};

export const getRangesByCategory = (categoryCode: string) => {
	switch (categoryCode) {
		case 'FLO':
		case 'VID':
			return [0, 4000, 250];
		case 'WPH':
			return [0, 5000, 250];
		default:
			return [0, 20000, 500];
	}
};

export const getAppliedStartingPriceFilterOptions = (
	min: string | undefined,
	max: string | undefined,
) => {
	const appliedFilters = [];
	if (min && !Number.isNaN(Number(min))) {
		appliedFilters.push({
			id: 'starting-price-min',
			name: 'starting-price-min',
			value: min,
			categorySlug: 'minPrice',
		});
	}
	if (max && !Number.isNaN(Number(max))) {
		appliedFilters.push({
			id: 'starting-price-max',
			name: 'starting-price-max',
			value: max,
			categorySlug: 'maxPrice',
		});
	}
	return appliedFilters;
};

const pricingCombos = [
	'REC-inexpensive',
	'REC-affordable',
	'REC-luxury',
	'WPH-affordable',
	'VID-affordable',
	'VID-luxury',
	'FLO-affordable',
];

export const determineIsVanityFacet = (
	categoryCode: string,
	applied: AppliedFilterPillProps[],
) => {
	const appliedValue = applied.length === 1 ? applied[0].value : '';
	return pricingCombos.includes(`${categoryCode}-${appliedValue}`);
};

export const getSelectedStartingPriceFilterOption = (
	appliedFilters: AppliedFilterPillProps[],
) => {
	const min = appliedFilters.find(
		(filter) => filter.categorySlug === 'minPrice',
	)?.value;
	const max = appliedFilters.find(
		(filter) => filter.categorySlug === 'maxPrice',
	)?.value;
	if (!(min || max)) return undefined;
	return `starting_price-${min || 'min'}-${max || 'max'}`;
};
