import constants from '../../pages/VendorsSearch/constants';

export const initialState = {
	categories: [], // The possible filters for the given page. Preloaded and shouldn't really change.
	categoryFilters: {}, // Selected filters in filter panel; not necessarily applied or actively filtered on.
	appliedFilters: {}, // Filters that the current subset are actually filtered against.
};

// This is only called on the server for initial load.
function populateFilters(filters, query, facetParam) {
	const filterMap = {};
	const categories = [];

	filters.forEach((filter) => {
		const slug =
			filter.slug !== undefined ? filter.slug : filter.name.toLowerCase();
		const appliedFilters =
			query[slug] !== undefined ? query[slug].split(' ') : [];
		if (facetParam) {
			appliedFilters.push(facetParam);
		}

		// Check for applied filters
		filterMap[slug] = [];
		categories.push(slug);

		filter.filterOptions.forEach((option) => {
			const singularSlug =
				option.singular.slug || option.name.toLowerCase().replace(' ', '-');
			const pluralSlug = option.plural?.slug || null;
			let otherAvailableSlugs = [];
			if (option?.seoSlugs) {
				otherAvailableSlugs = option.seoSlugs.map((other) => other.slug);
			}

			if (
				appliedFilters.includes(singularSlug) ||
				appliedFilters.includes(pluralSlug) ||
				appliedFilters.some((filter) => otherAvailableSlugs.includes(filter))
			) {
				filterMap[slug].push({
					id: option.id,
					value: singularSlug,
					term: option.singular.term,
				});
			}
		});
	});

	return {
		categories,
		appliedFilters: filterMap,
		categoryFilters: filterMap,
	};
}

export default function filtersReducer(state = initialState, action) {
	switch (action.type) {
		case constants.CLEAR_FILTERS:
			return {
				...state,
				categoryFilters: {
					...action.payload.filters,
				},
				appliedFilters: {
					...action.payload.filters,
				},
			};
		case constants.UPDATE_MULTI_FILTER:
		case constants.UPDATE_SINGLE_FILTER:
		case constants.CHANGE_PILL_FILTER:
			return {
				...state,
				categoryFilters: {
					...state.categoryFilters,
					[action.payload.category]: action.payload.values,
				},
			};
		case constants.UPDATE_CATEGORY_FILTER:
			return {
				...state,
				categoryFilters: action.payload,
			};
		case constants.SET_CATEGORY_FILTERS:
		case constants.POPULATE_CATEGORY_FILTERS:
			// eslint-disable-next-line no-case-declarations
			const {
				filters: { filters },
				query,
				facetParam,
			} = action.payload;
			return {
				...state,
				...populateFilters(filters, query, facetParam),
			};
		case constants.FETCH_SEARCH_RESULTS_SUCCESS:
			return {
				...state,
				appliedFilters: state.categoryFilters,
			};
		default:
			return state;
	}
}

export function isBOWFilterApplied(state) {
	const BOWFilter = getFilterApplied(state, 'best-of-weddings');
	return (
		BOWFilter &&
		undefined !== BOWFilter.find((filter) => filter.value === 'current-winner')
	);
}

export function getFilterApplied(state, filterName) {
	const { filters } = state;
	return filters.appliedFilters[filterName];
}
