import { experiments } from '@settings';
import { createSelector } from 'reselect';

export const selectGatekeeper = (state: Redux.State) =>
	state.experiments.gatekeeper;
export const selectAssignments = (state: Redux.State) =>
	state.experiments.assignments;

export const selectGatekeeperAssignment = createSelector(
	[
		selectGatekeeper,
		<T extends keyof Experiments.Settings>(_state: Redux.State, lookup: [T]) =>
			lookup[0],
	],
	(gatekeeper, lookup) => (gatekeeper[lookup] ? gatekeeper[lookup] : null),
);

export const selectGatekeeperValue = createSelector(
	[
		selectGatekeeper,
		<T extends keyof Experiments.Settings>(
			_state: Redux.State,
			lookup: [T, Experiments.Lookup<T>],
		) => lookup,
	],
	(gatekeeper, lookup): boolean | null => {
		if (typeof gatekeeper[lookup[0]] === 'undefined') {
			return null;
		}
		const providedAssignments = Array.isArray(lookup[1])
			? lookup[1]
			: [lookup[1]];
		return providedAssignments.some(
			(assignment) =>
				gatekeeper[lookup[0]] === assignment || gatekeeper[lookup[0]] === true,
		);
	},
);

export const selectExperimentAssignment = createSelector(
	[
		selectAssignments,
		<T extends keyof Experiments.Settings>(_state: Redux.State, lookup: [T]) =>
			lookup[0],
	],
	(assignments, lookup) => {
		const thing = assignments[experiments[lookup]];
		return thing;
	},
);

export const selectExperimentValue = createSelector(
	[
		selectAssignments,
		<T extends keyof Experiments.Settings>(
			_state: Redux.State,
			lookup: [T, Experiments.Lookup<T>],
		) => lookup,
	],
	(assignments, lookup): boolean => {
		const providedAssignments = Array.isArray(lookup[1])
			? lookup[1]
			: [lookup[1]];
		const hadNull = providedAssignments.includes(null);
		if (hadNull && !assignments[experiments[lookup[0]]]) {
			return true;
		}
		return providedAssignments.some(
			(assignment) => assignments[experiments[lookup[0]]] === assignment,
		);
	},
);

export const selectAssignment = createSelector(
	[selectGatekeeperAssignment, selectExperimentAssignment],
	(gatekeeper, experiment) => (gatekeeper !== null ? gatekeeper : experiment),
);

export const selectValue = createSelector(
	[selectGatekeeperValue, selectExperimentValue],
	(gatekeeper, experiment): boolean =>
		gatekeeper !== null ? gatekeeper : experiment,
);

export const isInVenueCategory = (state: Redux.State) =>
	state.category && state.category.code === 'REC';
