import conversationsReducer from '@xo-union/store-conversations-redux';
import { membershipReducer } from '@xo-union/store-membership-redux';
import { combineReducers } from 'redux';
import gptAdsReducer from '../components/shared/DynamicGPT/redux';
// keeping separate so we can break DynamicGPT out into a package later
import alert from './alert';
import bow from './bow';
import categories from './categories';
import category from './category';
import config from './config';
import convo from './convo';
import experiments from './experiments';
import faqReducer from './faq';
import favorites from './favorites';
import filters from './filters';
import location from './location';
import media from './media';
import messagedVendors from './messagedVendors';
import multiVendor from './multiVendor';
import optimize from './optimize';
import page from './page';
import requestInfo from './request';
import rfq from './rfq';
import search from './search';
import searchPageLocation from './searchPageLocation';
import seo from './seo';
import settings from './settings';
import statePage from './statePage';
import states from './states';
import typeahead from './typeahead';
import vendor from './vendor';
import vendorImpressions from './vendorImpressions';
import vendors from './vendors';
import viewport from './viewport';
import visitor from './visitor';
import vrm from './vrm';
import vrmModal from './vrmModal';
import wedding from './wedding';
import widget from './widget';

export default combineReducers({
	alert,
	bow,
	categories,
	category,
	config,
	conversations: conversationsReducer,
	convo,
	experiments,
	favorites,
	faq: faqReducer,
	filters,
	gptAds: gptAdsReducer,
	location,
	media,
	membership: membershipReducer,
	multiVendor,
	page,
	statePage,
	states,
	vendors,
	vendor,
	requestInfo,
	searchPageLocation,
	rfq,
	search,
	seo,
	settings,
	typeahead,
	vendorImpressions,
	viewport,
	vrm,
	visitor,
	optimize,
	wedding,
	messagedVendors,
	vrmModal,
	widget,
});

// Membership comes in from Union
export const fetchingMember = (state) => state.membership.inProgress.FETCH;
export const getMember = (state) => state.membership.member;
export const getMemberId = (state) => getMember(state) && getMember(state).id;
