import fetch from 'isomorphic-fetch';
import { conversationsApi, conversationsAuthApi } from '../../../settings';

export const CONSTANTS = {
	FETCH_CONVERSATIONS: 'FETCH_CONVERSATIONS',
	FETCH_CONVERSATIONS_SUCCESS: 'FETCH_CONVERSATIONS_SUCCESS',
	FETCH_CONVERSATIONS_FAILURE: 'FETCH_CONVERSATIONS_FAILURE',
	FETCH_CONVERSATIONS_AUTH: 'FETCH_CONVERSATIONS_AUTH',
	FIND_MATCHING_CONVERSATION: 'FIND_MATCHING_CONVERSATION',
	CLEAR_CONVERSATIONS: 'CLEAR_CONVERSATIONS',
};

export const initialState = {
	conversationId: null,
	conversations: [],
	error: null,
	isFetching: false,
	vendorIdsWithConversations: [],
};

export default function convoReducer(state = initialState, action) {
	switch (action.type) {
		case CONSTANTS.FETCH_CONVERSATIONS_AUTH:
			return { ...state, isFetching: true };
		case CONSTANTS.FETCH_CONVERSATIONS:
			return { ...state, isFetching: true };
		case CONSTANTS.FETCH_CONVERSATIONS_SUCCESS:
			return {
				...state,
				isFetching: false,
				conversations: action.response.conversations,
				vendorIdsWithConversations: action.response.conversations.map(
					(obj) => obj.storefrontInfo.id,
				),
			};
		case CONSTANTS.FETCH_CONVERSATIONS_FAILURE:
			return { ...state, isFetching: false, error: action.error };
		case CONSTANTS.FIND_MATCHING_CONVERSATION:
			return { ...state, conversationId: action.response };
		case CONSTANTS.CLEAR_CONVERSATIONS:
			return { ...state, conversations: null, conversationId: null };
		default:
			return state;
	}
}

export function fetchConversations(memberId, membershipSessionToken) {
	const headers = {
		'membership-session-token': membershipSessionToken,
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};

	return (dispatch) => {
		dispatch({ type: CONSTANTS.FETCH_CONVERSATIONS_AUTH });

		return fetch(`${conversationsAuthApi}`, { method: 'POST', headers }).then(
			async (response) => {
				const jwtAuth = await response.text();
				dispatch({ type: CONSTANTS.FETCH_CONVERSATIONS });
				fetch(`${conversationsApi}/${memberId}`, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${jwtAuth}`,
					},
				})

					.then(async (resp) => {
						const body = await resp.text();
						dispatch({
							type: CONSTANTS.FETCH_CONVERSATIONS_SUCCESS,
							response: JSON.parse(body),
						});
					})
					.catch(async (e) => {
						dispatch({ type: CONSTANTS.FETCH_CONVERSATIONS_FAILURE, error: e });
					});
			},
		);
	};
}

export function findMatchingConversation(storefrontId, conversations) {
	const match = conversations.filter(
		(obj) => obj.storefrontInfo.id === storefrontId,
	);
	return {
		type: CONSTANTS.FIND_MATCHING_CONVERSATION,
		response: match[0] ? match[0].id : null,
	};
}

export function clearConversations() {
	return {
		type: CONSTANTS.CLEAR_CONVERSATIONS,
	};
}
