import initialState from './initialState';

const rfqReducer = (state = initialState, action: Rfq.ActionTypes) => {
	switch (action.type) {
		case 'rfq/OPEN_MODAL':
			return {
				...state,
				modalOpen: true,
			};

		case 'rfq/CLOSE_MODAL':
			return {
				...state,
				modalOpen: false,
			};

		case 'rfq/SET_BUTTON_TYPE':
			return {
				...state,
				buttonType: action.payload,
			};

		case 'rfq/SET_FORM_DATA':
			return {
				...state,
				rfqFormData: action.payload,
			};

		case 'rfq/CLEAR_FORM_DATA':
			return {
				...state,
				rfqFormData: {},
			};

		case 'rfq/UPDATE_LAST_VENDOR_NAME_SENT':
			return {
				...state,
				lastVendorNameSent: action.payload,
			};

		case 'rfq/SET_STOREFRONT_ID':
			return {
				...state,
				storefrontId: action.payload,
			};

		case 'rfq/SET_REGISTRATION_STATUS':
			return {
				...state,
				isUserRegistered: action.payload,
			};

		case 'rfq/SET_ACCOUNT_CREATION_STATUS':
			return {
				...state,
				creationSuccessful: action.payload,
			};

		case 'rfq/SET_USER_JOURNEY_STATUS':
			return {
				...state,
				userJourneyStatus: action.payload,
			};

		case 'rfq/SET_CHANGE_PASSWORD_STATUS':
			return {
				...state,
				changePasswordStatus: {
					changeSuccessful: action.payload.changeSuccessful,
					message: action.payload.message,
				},
			};

		case 'rfq/UPDATE_FIELD_CHANGE': {
			const { field, name } = action.payload;
			return {
				...state,
				inline: {
					...state.inline,
					fields: {
						...state.inline.fields,
						[name]: field,
					},
				},
			};
		}

		case 'rfq/UPDATE_ALL_FIELDS_CHANGE': {
			const {
				values: { isDateFlexible, ...fields },
			} = action.payload;
			const thing = {
				...state,
				inline: {
					...state.inline,
					isDateFlexible,
					fields: {
						...state.inline.fields,
						...fields,
					},
				},
			};

			return thing;
		}

		case 'rfq/UPDATE_IS_DATE_FLEXIBLE':
			return {
				...state,
				inline: {
					...state.inline,
					isDateFlexible: action.payload,
				},
			};

		case 'rfq/UPDATE_IS_DATE_PICKER_OPEN':
			return {
				...state,
				inline: {
					...state.inline,
					isDatePickerOpen: action.payload,
				},
			};

		case 'rfq/UPDATE_SHOW_ERRORS':
			return {
				...state,
				inline: {
					...state.inline,
					showErrors: action.payload,
				},
			};

		case 'rfq/UPDATE_ARE_ERRORS_IN_FORM':
			return {
				...state,
				inline: {
					...state.inline,
					areErrorsInForm: action.payload,
				},
			};

		case 'rfq/UPDATE_IS_INLINE_FORM_OPEN':
			return {
				...state,
				inline: {
					...state.inline,
					isInlineFormOpen: action.payload,
				},
			};

		default:
			return state;
	}
};

export { default as initialState } from './initialState';
export default rfqReducer;
