export const initialState = {
	experiments: {},
};

export const SET_EXPERIMENT_INFO = 'SET_EXPERIMENT_INFO';

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_EXPERIMENT_INFO:
			return {
				...state,
				experiments: {
					...state.experiments,
					[action.payload.experimentId]: action.payload.variant,
				},
			};

		default:
			return state;
	}
}

export const setExperimentInfo = (experimentId, variant) => ({
	type: SET_EXPERIMENT_INFO,
	payload: { experimentId, variant },
});
