import { awards as awardsSettings } from '@settings';
import Constants from '../../../constants/best_of_weddings';
import { awardsInfo } from '../../utils/awardsInfo';
import withinDateRange from '../../utils/withinDateRange';

const { bow, hof } = awardsInfo();
const { startDate, endDate } = awardsSettings;

export const getAwardType = () =>
	withinDateRange({ startDate, endDate }) ? bow : hof;

export const initialState = {
	currentYear: bow.year,
	totalBOWVendors: 0,
	vendors: [],
	awardType: getAwardType(),
	category: Constants.DEFAULT_CATEGORY,
	location: {
		city: '',
		stateCode: '',
		market: '',
	},
	isFetching: false,
	searchCompleted: false,
};

export default function bowReducer(state = initialState, action) {
	switch (action.type) {
		case Constants.CHANGED_CATEGORY:
			return {
				...state,
				searchCompleted: false,
				isFetching: false,
				category: action.category,
				vendors: [],
			};
		case Constants.CHANGED_LOCATION:
			return {
				...state,
				searchCompleted: false,
				isFetching: false,
				location: action.location,
				vendors: [],
			};
		case Constants.CHANGED_AWARD_TYPE:
			return {
				...state,
				searchCompleted: false,
				isFetching: false,
				awardType: action.awardType,
				vendors: [],
			};
		case Constants.FETCH_VENDORS:
			return {
				...state,
				searchCompleted: false,
				isFetching: true,
				vendors: [],
			};
		case Constants.FETCH_VENDORS_SUCCESS:
			return {
				...state,
				totalBOWVendors: action.payload.data.search.profiles.length,
				vendors: action.payload.data.search.profiles,
				isFetching: false,
				searchCompleted: true,
			};

		case Constants.FETCH_VENDORS_ERROR:
			return {
				...state,
				error: action.error,
				isFetching: false,
				searchCompleted: true,
				vendors: [],
			};
		default:
			return state;
	}
}
