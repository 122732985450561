export const CATEGORY_LOADED = '@fe-marketplace/category/loaded';

/** @type {Redux.Category}  */
export const initialState = {
	code: 'REC',
	id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
	name: 'Reception Venues',
	description: 'Venues suitable for hosting wedding receptions. ',
	singular: {
		slug: 'wedding-reception-venue',
		term: 'Wedding Venue',
	},
	plural: {
		slug: 'wedding-reception-venues',
		term: 'Wedding Venues',
	},
	seo: {
		plural: {
			slug: 'wedding-photographers',
			term: 'Wedding Photographers',
		},
		singular: {
			slug: 'wedding-photographer',
			term: 'Wedding Photographer',
		},
	},
	display: {
		patternsIconClass: 'icon-vendor-rec',
	},
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case CATEGORY_LOADED:
			return {
				...state,
				...action.payload,
				...action.payload.seo,
			};
		default:
			return state;
	}
}

export const getCategory = (state) => state.category;
export const getCategoryCode = (state) => state.category.code;
export const getCategoryId = (state) => state.category.id;
export const getCategoryName = (state) => state.category.name;
export const getCategorySlug = (state) => state.category.plural.slug;
export const getCategoryPluralTerm = (state) => state.category.plural.term;
