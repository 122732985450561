import { generateDefaultField } from '../../pages/Storefront/containers/InlineRFQ/utils';

const initialState: Redux.Rfq = {
	buttonType: '',
	changePasswordStatus: {
		changeSuccessful: 'init',
		message: '',
	},
	creationSuccessful: 'init',
	currentBadges: [],
	inline: {
		areErrorsInForm: true,
		fields: {
			emailAddress: generateDefaultField('emailAddress'),
			firstName: generateDefaultField('firstName'),
			guestCount: generateDefaultField('guestCount'),
			lastName: generateDefaultField('lastName'),
			phoneNumber: generateDefaultField('phoneNumber'),
			textarea: generateDefaultField('textarea'),
			weddingDate: generateDefaultField('weddingDate'),
		},
		isDateFlexible: false,
		isDatePickerOpen: false,
		isInlineFormOpen: true,
		showErrors: false,
	},
	isUserRegistered: false,
	lastVendorNameSent: null,
	modalOpen: false,
	rfqFormData: {},
	storefrontId: null,
	userJourneyStatus: 'init',
};

export default initialState;
