export const LOCATION_UPDATED = '@fe-marketplace/locations/updated';
const CHANGE_LOCATION = 'CHANGE_LOCATION';

export const initialState = {
	city: null,
	marketCode: null,
	stateCode: null,
	isUpdating: true,
	ip: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case CHANGE_LOCATION:
			return {
				...state,
				...action.location,
			};
		case LOCATION_UPDATED:
			return {
				...state,
				city: action.payload.city,
				marketCode: action.payload.market,
				stateCode: action.payload.stateCode,
				isUpdating: false,
				ip: action.payload.ip,
			};
		default:
			return state;
	}
}

export function updateLocation(locationHash, ip) {
	return {
		type: LOCATION_UPDATED,
		payload: { ...locationHash, ip },
	};
}

export const getLocation = (state) => state.location;
export const getCity = (state) => state.location.city;
export const getMarketCode = (state) => state.location.marketCode;
export const getStateCode = (state) => state.location.stateCode;
export const getIpAddress = (state) => state.location.ip;
