import global from 'global';

const { localStorage } = global;

class LocalStorage {
	constructor(key) {
		this._key = key;
	}

	get() {
		return JSON.parse(localStorage.getItem(this.key)) || {};
	}

	set(payload) {
		localStorage.setItem(this.key, JSON.stringify(payload));
	}

	clear() {
		localStorage.removeItem(this.key);
	}

	get key() {
		return this._key;
	}
}

const instance = new LocalStorage('xo_rfq_v3');

export default instance;
