import { useInjectFavoritesNavLink } from '@hooks/use-inject-favorites-nav-link';
import HeaderNav from '@xo-union/tk-component-header-nav-redux/remote';
import '@xo-union/tk-ui-essentials';
import { isInSemanticSearchSelector } from '@redux/experiments/selectors/semantic-search';
import { useAppSelector } from '@redux/hooks';
import { useAtomValue } from 'jotai';
import React, { useRef } from 'react';
import analytics from '../../../constants/analytics';
import { isSemanticSearchCalledAtom } from '../VendorsSearch/containers/SemanticSearch/atoms';

interface Props {
	analyticsProps: { product: string };
	metadata: { applicationName: string };
	loggedIn: boolean;
}

export const HeaderNavWithInjectedLinks = ({
	analyticsProps,
	metadata,
	loggedIn,
}: Props) => {
	const headerNavRef = useRef<HTMLDivElement>(null);
	const isSemanticSearch = useAppSelector((state) =>
		isInSemanticSearchSelector(state),
	);
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	useInjectFavoritesNavLink(headerNavRef);

	const handleMetaData = () => {
		if (isSemanticSearch && isSemanticSearchCalled) {
			return {
				...metadata,
				additionalAnalyticsProperties: {
					source_page: analytics.SEMANTIC_RESULTS,
				},
			};
		}
		return metadata;
	};

	const updatedMetadata = handleMetaData();

	return (
		<div ref={headerNavRef}>
			<HeaderNav
				ssi
				analyticsProps={analyticsProps}
				metadata={updatedMetadata}
				loggedIn={loggedIn}
				activeProduct="vendors"
			/>
		</div>
	);
};
