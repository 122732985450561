export default {
	VENDOR_TIER_PREMIUM: 'PREMIUM',
	VENDOR_TIER_FEATURED: 'FEATURED',
	VENDOR_TIER_FULLPAGE: 'FULLPAGE',

	CHANGE_LOCATION: 'CHANGE_LOCATION',
	CHANGE_PILL_FILTER: 'filters/CHANGE_PILL_FILTER',

	FETCH_SEARCH_RESULTS: 'FETCH_SEARCH_RESULTS',
	FETCH_SEARCH_RESULTS_SUCCESS: 'FETCH_SEARCH_RESULTS_SUCCESS',
	FETCH_SEARCH_RESULTS_ERROR: 'FETCH_SEARCH_RESULTS_ERROR',

	CHANGE_FILTER: 'filters/CHANGE_FILTER',
	CLEAR_FILTERS: 'filters/CLEAR_FILTERS',
	SET_CATEGORY_FILTERS: 'filters/SET_CATEGORY_FILTERS',
	POPULATE_CATEGORY_FILTERS: 'filters/POPULATE_CATEGORY_FILTERS',
	UPDATE_MULTI_FILTER: 'filters/CHANGE_MULTI_FILTER',
	UPDATE_SINGLE_FILTER: 'filters/CHANGE_SINGLE_FILTER',
	UPDATE_CATEGORY_FILTER: 'filters/UPDATE_CATEGORY_FILTER',
	UPDATE_FOLLOWABLE_FACETS: 'filters/UPDATE_FOLLOWABLE_FACETS',
	UPDATE_PAGE: 'filters/UPDATE_PAGE',
	UPDATE_SORT: 'filters/UPDATE_SORT',

	UPDATE_SEO_FILTER_VALUE: 'UPDATE_SEO_FILTER_VALUE',
	UPDATE_SEO_CANONICAL_URLS: 'UPDATE_SEO_CANONICAL_URLS',

	RESET_FILTER: 'reset filters',
	SELECT_FILTER: 'select filter option',
	DESELECT_FILTER: 'deselect filter option',

	BLOCKLISTED_MARKETS: ['224', '084', '042', '205', '050', '012', '272'],

	SELECTED_MAP_AREA: 'Selected Map Area',
	SUB_TEXT: 'How do we sort results, including Sponsored Ads?',
	TOOLTIP_TEXT:
		'Results are sorted based on vendor details like participation in our paid advertising program, distance from your preferred location, and relevance to your search and filters.',
} as const;
