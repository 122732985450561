import type {
	ClearFaqsAction,
	FaqActionTypes,
	FetchFaqsAction,
} from 'types/faq/actionTypes';
import type { Faq } from 'types/redux';

export const initialState = { data: [] };

export const CLEAR_FAQS = 'faq/CLEAR_FAQS';
export const FETCH_FAQS = 'faq/FETCH_FAQS';

export const setDynamicFAQS = (data: Faq): FetchFaqsAction => ({
	type: FETCH_FAQS,
	data,
});

export const clearDynamicFAQS = (): ClearFaqsAction => ({ type: CLEAR_FAQS });

export default function faqReducer(
	state = initialState,
	action: FaqActionTypes,
) {
	switch (action.type) {
		case FETCH_FAQS:
			return { ...state, data: action.data };
		case CLEAR_FAQS:
			return { ...state, data: [] };
		default:
			return state;
	}
}
