export * from './actionTypes';

export const initialState: Redux.MessagedVendors = {
	conversations: {},
	isFetching: true,
	timestamps: [],
};

export default function reducer(
	state = initialState,
	action: MessagedVendors.ActionTypes,
) {
	switch (action.type) {
		case 'CONVERSATIONS_LOADED':
			return {
				...state,
				conversations: action.payload,
				isFetching: false,
			};
		case 'CONVERSATION_ADDED':
			return {
				...state,
				conversations: { ...state.conversations, ...action.payload },
				isFetching: false,
			};
		case 'SET_CONVERSATION_TIMESTAMPS':
			return {
				...state,
				timestamps: [...state.timestamps, ...action.payload],
			};
		default:
			return state;
	}
}
