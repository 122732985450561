import { cloneDeep } from 'lodash';
import * as constants from './constants';

export * from './actionCreators';

const reducer = (
	state: Redux.Alert = constants.initialState,
	action: Alert.ActionTypes,
) => {
	const stateCopy = cloneDeep(state);
	switch (action.type) {
		case 'alert/SET_CONTENT': {
			stateCopy.content = action.content;
			stateCopy.link = action.link;
			break;
		}
		case 'alert/SET_IS_OPEN': {
			stateCopy.isOpen = action.status;
			break;
		}
		case 'alert/SET_CONTENT_AND_STATUS': {
			stateCopy.isOpen = action.status;
			stateCopy.content = action.content;
			stateCopy.link = action.link;
			break;
		}
		default:
			return state;
	}
	return stateCopy;
};

export default reducer;
