export const CATEGORIES_LOADED = 'CATEGORIES_LOADED';

const initialState = [];
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case CATEGORIES_LOADED:
			return action.payload;
		default:
			return state;
	}
}
