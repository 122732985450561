import { atom } from 'jotai';
import type { createStore } from 'jotai/vanilla';

export type PricingDetails = {
	reception: string | null;
	ceremony: string | null;
	barService: string | null;
	months: string | null;
};

type PricingState = {
	startingCost: string | null;
	couplesUsuallySpend: string | null;
	guestCapacity: string | null;
	pricingDetails: PricingDetails;
	pdfUrl: string | null;
};

export const initialState: PricingState = {
	startingCost: null,
	couplesUsuallySpend: null,
	guestCapacity: null,
	pricingDetails: {
		reception: null,
		ceremony: null,
		barService: null,
		months: null,
	},
	pdfUrl: null,
};

export const startingCostAtom = atom(initialState.startingCost);
export const guestCapacityAtom = atom(initialState.guestCapacity);
export const formattedGuestCapacityAtom = atom((get) => {
	const guestCapacity = get(guestCapacityAtom);
	if (!guestCapacity) return null;
	if (guestCapacity.includes('-')) {
		return `${guestCapacity.replace('-', ' to ')} guests`;
	}
	if (guestCapacity.includes('+')) {
		return `${guestCapacity} guests`;
	}
	return null;
});
export const guestCapacityMaxAtom = atom((get) => {
	const guestCapacity = get(guestCapacityAtom);
	if (!guestCapacity) return null;
	if (!guestCapacity.includes('-')) return guestCapacity;
	return guestCapacity.split('-')[1];
});
export const pricingDetailsAtom = atom(initialState.pricingDetails);
export const formattedPricingDetailsAtom = atom((get) => {
	const pricingDetails = get(pricingDetailsAtom);

	return {
		reception: pricingDetails.reception || 'Contact for price',
		ceremony: pricingDetails.ceremony || 'Contact for price',
		barService: pricingDetails.barService
			? `${pricingDetails.barService} per person`
			: 'Contact for price',
	};
});
export const hasPricingDetailsAtom = atom((get) => {
	const { reception, ceremony, barService } = get(pricingDetailsAtom);
	return Boolean(reception || ceremony || barService);
});

export const pdfUrlAtom = atom(initialState.pdfUrl);
export const hasPdfUrlAtom = atom((get) => {
	const pdfUrl = get(pdfUrlAtom);
	return Boolean(pdfUrl);
});
export const hasPricingAtom = atom((get) => {
	const startingCost = get(startingCostAtom);
	const guestCapacity = get(guestCapacityAtom);
	const hasPdfUrl = get(hasPdfUrlAtom);
	return Boolean(startingCost || guestCapacity || hasPdfUrl);
});
export const couplesUsuallySpendAtom = atom(initialState.couplesUsuallySpend);

export const getPricingDataToHydrate = (
	store: ReturnType<typeof createStore>,
) => {
	const startingCost = store.get(startingCostAtom);
	const guestCapacity = store.get(guestCapacityAtom);
	const pricingDetails = store.get(pricingDetailsAtom);
	const pdfUrl = store.get(pdfUrlAtom);
	const couplesUsuallySpend = store.get(couplesUsuallySpendAtom);
	return {
		startingCost,
		guestCapacity,
		pricingDetails,
		pdfUrl,
		couplesUsuallySpend,
	};
};

export const getPricingInitialValues = (
	values: ReturnType<typeof getPricingDataToHydrate>,
) => {
	const {
		startingCost,
		guestCapacity,
		pricingDetails,
		pdfUrl,
		couplesUsuallySpend,
	} = values || {};
	return [
		[startingCostAtom, startingCost || initialState.startingCost],
		[guestCapacityAtom, guestCapacity || initialState.guestCapacity],
		[pricingDetailsAtom, pricingDetails || initialState.pricingDetails],
		[pdfUrlAtom, pdfUrl || initialState.pdfUrl],
		[
			couplesUsuallySpendAtom,
			couplesUsuallySpend || initialState.couplesUsuallySpend,
		],
	];
};
