export const initialState = {
	dataIntelJwtToken: '',
	noGtm: false,
};

const SET_DATA_INTEL_JWT_TOKEN = 'SET_DATA_INTEL_JWT_TOKEN';
const SET_NO_GTM = 'SET_NO_GTM';

export default function configReducer(state = initialState, action) {
	switch (action.type) {
		case SET_DATA_INTEL_JWT_TOKEN:
			return {
				...state,
				dataIntelJwtToken: action.payload.token,
			};
		case SET_NO_GTM:
			return {
				...state,
				noGtm: action.payload.noGtm,
			};
		default:
			return state;
	}
}

export function setDataIntelJWTToken(token) {
	return {
		type: SET_DATA_INTEL_JWT_TOKEN,
		payload: {
			token,
		},
	};
}

export function setNoGtm(noGtm) {
	return {
		type: SET_NO_GTM,
		payload: {
			noGtm,
		},
	};
}
