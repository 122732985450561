import { selectAssignment } from '@redux/experiments/selectors/index';
import type { SemanticSearchAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

export const semanticSearchAssignmentSelector = (state: State) => {
	return selectAssignment(state, [
		'semanticSearch',
	]) as SemanticSearchAssignment;
};

export const isInSemanticSearchSelector = (state: State) => {
	const assignment = semanticSearchAssignmentSelector(state);
	return assignment === 'semantic-search';
};
