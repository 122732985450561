import reorderMedia from '../../../helpers/media_seed_helper';

const CAROUSEL_DEFAULT_LEFT_IMAGE_COUNT = 3;

export const initialState: Redux.Media = {
	mediaInfo: [],
	tourUrl: '',
	spotlightModalOpen: false,
};

export default function mediaReducer(
	state = initialState,
	action: Media.ActionTypes,
) {
	switch (action.type) {
		// TODO: Add tests for this.
		case '@fe-marketplace/vendor/loaded': // set initial media state
			return {
				...state,
				mediaInfo: reorderMedia(
					action.payload.mediaSummary.media,
					CAROUSEL_DEFAULT_LEFT_IMAGE_COUNT,
				),
			};
		case 'media/TOUR_MODAL':
			return {
				...state,
				tourUrl: action.payload.url,
				spotlightModalOpen: action.payload.show,
			};
		default:
			return state;
	}
}
