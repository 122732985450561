import { selectAssignment } from '@redux/experiments/selectors/index';
import type { NewHeartAssignment } from '@typings/experiments';
import { CookieStorage } from 'cookie-storage';
import type { State } from 'types/redux';
import { XO_SESSION_TOKEN } from '../../../../constants/membership';

const newHeartAssignmentSelector = (state: State) => {
	const cookieStorage = new CookieStorage();
	const membershipSessionToken = cookieStorage.getItem(XO_SESSION_TOKEN);

	return membershipSessionToken
		? (selectAssignment(state, ['newHeart']) as NewHeartAssignment)
		: null;
};

export { newHeartAssignmentSelector };
