import { cloneDeep } from 'lodash';
import initialState from './initialState';
import { deserialize } from './utils';

export * from './actions';

const reducer = (state = initialState, action: Experiments.ActionTypes) => {
	const stateCopy = cloneDeep(state);
	switch (action.type) {
		case 'experiments/SET_GATEKEEPER': {
			if (action.query === 'original') {
				stateCopy.assignments = {};
				stateCopy.gatekeeper = {};
			} else {
				const gatekeeper = deserialize(action.query);
				stateCopy.gatekeeper = gatekeeper;
			}
			break;
		}
		case 'experiments/RECEIVE_EXPERIMENT_CONFIGURATIONS': {
			stateCopy.configurations = {
				...stateCopy.configurations,
				...action.configurations,
			};
			break;
		}
		case 'experiments/RECEIVE_MULTIPLE_ASSIGNMENTS': {
			stateCopy.assignments = {
				...stateCopy.assignments,
				...action.assignments,
			};
			break;
		}
		default:
			return state;
	}
	return stateCopy;
};

export default reducer;
